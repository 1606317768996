




































import { Component } from 'vue-property-decorator';
import BaseComponent from '../components/BaseComponent';
import ProjectsTableComponent from '../components/ProjectsTableComponent.vue';
import ExpenseComponent from '../components/ExpenseComponent.vue';
import ErrorComponent from '../components/ErrorComponent.vue';
import DialogComponent from '../components/DialogComponent.vue';
import { handleError } from '../lib/shared';
import * as _ from 'lodash';

@Component({
    components: {
      ErrorComponent,
      DialogComponent,
      ProjectsTableComponent,
      ExpenseComponent,
    },
  })
export default class PayeeProjects extends BaseComponent {

  public arrErrors: Error[] = [];
  protected baseRoute: string = '/payees';

  private payeeId: string = '';
  private bAddPayee: boolean = false;
  private bAddExpense: boolean = false;
  private editedPayeeId: string = '';

  private updateExpense: number = 0;
  private updatePayee: number = 0;
  private updatePayees: number = 0;

  private updateProjectPayee: number = 0;

  protected mounted() {
    this.mount();
  }

  protected newPayeeItem() {
    this.editedPayeeId = '';
    this.bAddPayee = true;
  }

  protected newExpenseItem() {
    this.bAddExpense = true;
  }

  private mount() {
    if (this.$route.path.endsWith('new-payee')) {
      this.newPayeeItem();
    }
    if (this.$route.path.endsWith('new-expense')) {
      this.newExpenseItem();
    }
    this.payeeId = this.$route.params.id;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  private refreshPayee() {
    this.updatePayee++;
  }

  private refreshPayees() {
    this.updatePayees++;
    this.mount();
  }

  private closeEditPayee() {
    this.bAddPayee = false;
  }

  private editPayee(id) {
    this.editedPayeeId = id;
    this.bAddPayee = true;
  }

  private navPayee(id) {
    this.$router.push({ path: '/projects/id/' + this.$route.params.id + '/payee/id/' + id});
  }
}
