












































































































































import { Component, Watch, Prop } from 'vue-property-decorator';
import BaseComponent from './BaseComponent';
import ErrorComponent from './ErrorComponent.vue';
import ProjectComponent from './ProjectComponent.vue';
import DeleteModalComponent from './DeleteModalComponent.vue';
import ActionButtonComponent from './ActionButtonComponent.vue';
import SpinnerComponent from './SpinnerComponent.vue';
import DialogComponent from './DialogComponent.vue';
import FiltersComponent from './FiltersComponent.vue';
import MobileBottomNavComponent from "./MobileBottomNavComponent.vue";
import { searchProjects, deleteProject, getPayeeProjects, getClientProjects, searchAggregatesByRelation } from '../store/rest.service';
import { SearchResults, ResourceSearchResult, Project, ReferenceData, SortColumnMapping, ResultMeta, GeneralSearchCriteria, AggSearchCriteria, ProjectSearchCriteria } from '../store/models';
import { EmptyGeneralSearchCriteria, EmptyAggSearchCriteria, EmptyProjectSearchCriteria } from '../store/models-empty';
import { handleError, getPagingState, setPagingState } from '../lib/shared';
import { getTNBs, getSocialProcurement, getDIS } from '../lib/aggregate';
import { getIconUrl } from '../lib/images';
import * as _ from 'lodash';
import { setProjectsSearchFacets } from '@/lib/facets';

@Component({
  components: {
    ErrorComponent,
    ProjectComponent,
    DeleteModalComponent,
    SpinnerComponent,
    DialogComponent,
    ActionButtonComponent,
    FiltersComponent,
    MobileBottomNavComponent,
  },
})
export default class ProjectsTableComponent extends BaseComponent {

  @Prop() public hideFacets: any;
  @Prop() public payeeId: any;
  @Prop() public clientId: any;
  @Prop() public communityId: any;

  private getTNBs = getTNBs;
  private getSocialProcurement = getSocialProcurement;
  private getDIS = getDIS;
  private debounceSearch = _.debounce(this.getProjects, 500);

  private searchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
  private aggSearchCriteria: AggSearchCriteria = _.cloneDeep(EmptyAggSearchCriteria);
  private projects: ResourceSearchResult|null = null;
  private projectsLoading: boolean = false;

  private payeeProjects: ResourceSearchResult|null = null;
  private payeeProjectsLoading: boolean = false;
  private communityProjects: ResourceSearchResult|null = null;
  private communityProjectsLoading: boolean = false;
  private allProjects: Project[] = [];
  private projectCount: number = 0;

  private bDeleteProjectModal: boolean = false;
  private deleteProjectId: string = '';
  private deleteProjectName: string = '';
  private deleteProjectEtag: string = '';

  private arrErrors: Error[] = [];

  private bInitialLoad: boolean = true;
  private bInitialLoadWatch: boolean = true;
  private bPagingStateSet: boolean = false;
  private bNewProject: boolean = false;
  private updateProjectKey: number = 0;

  private vendors: ResourceSearchResult|null = null;
  private vendorsLoading: boolean = false;

  private actions: any[] = [
    { label: 'Project', code: 'project' },
  ];

  private projectStatusTypes: ReferenceData = {
    refData : {
      type: 'project-status-types',
      values: [
        { name: 'Active', code: 'live' },
        { name: 'Archived', code: 'archived' },
      ],
    },
  };

  // @ts-ignore
  private pagination: any = { sortBy: 'project.projectName', descending: false, page: 1, rowsPerPage: 25 };
  private rowsPerPage: number[] = [10, 25, 100];
  private searchTerms: string = '';
  private resetDate: number = 0;

  private sortMappings: SortColumnMapping[] = [
    {
      field: 'project.projectName',
      sortColumn: 'display-name',
    },
    {
      field: 'project.startDate',
      sortColumn: 'date',
    },
  ];

  private projectSearchCriteria: ProjectSearchCriteria = _.cloneDeep(EmptyProjectSearchCriteria);

  private headers = [
    { text: 'Project Name', align: 'left', value: 'project.projectName', class: this.getColumnClass() },
    { text: 'Client', align: 'left', value: 'project.creatorVendor.displayName', sortable: false, class: this.getColumnClass() },
    { text: 'Start Date', align: 'left', value: 'project.startDate', sortable: true, class: this.getColumnClass() },
    { text: 'Total Project<br>Revenue', align: 'right', value: 'totals.revenue.revenueTotals.totalProjectRevenue', sortable: false, class: this.getColumnClass(true) },
    { text: 'Indigenous Benefits', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project<br>Expense', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: this.getColumnClass(true) },
    { text: 'Direct Indigenous<br>Spend', align: 'right', value: 'totals.expenses.indigenousSpend', sortable: false, class: this.getColumnClass(true) },
    { text: 'Social Procurement', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Delete', align: 'left', sortable: false, class: this.getColumnClass() },
  ];

  private communityHeaders = [
    { text: 'Project Name', align: 'left', value: 'facetDetail.displayName', sortable: true, class: this.getColumnClass() },
    { text: 'Start Date', align: 'left', value: 'facetDetail.startDate', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project Revenue', align: 'right', value: 'totals.revenue.projectRevenue', sortable: false, class: this.getColumnClass() },
    { text: 'Direct Indigenous Spend', align: 'right', value: 'totals.expense.indigenousSpend', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project Expense', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: this.getColumnClass() },
  ];

  private headersForPayees = [
    { text: 'Project Name', align: 'left', value: 'project.projectName', class: this.getColumnClass() },
    // { text: 'Total Project Expenses', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: this.getColumnClass() },
    { text: 'Total Payee Spend', align: 'right', value: 'totals.expenses.expenseTotals.totalSpend', sortable: false, class: this.getColumnClass() },
  ];

  private headersForClient = [
    { text: 'Project Name', align: 'left', value: 'project.projectName', class: this.getColumnClass() },
    { text: 'Total Project Revenue', align: 'right', value: 'totals.revenue.revenueTotals.totalProjectRevenue', sortable: false, class: this.getColumnClass() },
    { text: 'Indigenous Benefits', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Total Project Expense', align: 'right', value: 'totals.expenses.expenseTotals.totalProjectSpend', sortable: false, class: this.getColumnClass() },
    { text: 'Direct Indigenous Spend', align: 'right', sortable: false, class: this.getColumnClass() },
    { text: 'Social Procurement', align: 'right', sortable: false, class: this.getColumnClass() },
  ];

  protected changeCurrentOrg() {
    this.updateProjectKey++;
    this.getProjects();
  }

  protected mounted() {
    this.mount();
  }

  private mount() {
    // Reload the paging state from session storage.
    const pagingState: any = getPagingState(this.$store, this.$route.path);
    if (pagingState) {
      this.pagination = pagingState;
    } else {
      this.bPagingStateSet = true;
    }
    this.getProjects();
    if (this.$route.path.endsWith('new-project')) {
      this.bNewProject = true;
    }
  }

  private updateFacetsOrFilters(facets: any) {
    this.getProjects();
  }

  private navigate(code) {
    switch (code) {
      case 'project':
        this.changeRoute('/projects/new-project', true);
        this.bNewProject = true;
        break;
    }
  }

  private isLoading(): boolean {
    return this.projectsLoading
      || this.payeeProjectsLoading
      || this.communityProjectsLoading;
  }

  private handleError(error) {
    handleError(this.arrErrors, error);
  }

  @Watch('pagination', { deep: true })
  private onPaginationChanged(val: any, oldVal: any) {
    if (this.bPagingStateSet) {
      if (!_.isEqual(val, oldVal) && !this.bInitialLoad) {
        if (!this.bInitialLoadWatch) {
          this.getProjects();
        }
      }
      setPagingState(this.$store, this.$route.path, this.pagination);
      this.bInitialLoad = false;
      this.bInitialLoadWatch = false;
    } else {
      this.bPagingStateSet = true;
    }
  }

  private closeDelete() {
    this.bDeleteProjectModal = false;
    this.getProjects();
  }

  private saveEditProject() {
    this.updateProjectKey++;
    this.getProjects();
  }

  private closeEditProject() {
    this.bNewProject = false;
  }

  private getEditIcon(): string {
    return getIconUrl('icon_edit.png');
  }

  private getDeleteIcon(): string {
    return getIconUrl('icon_delete.png');
  }

  private getTickIcon(): string {
    return getIconUrl('icon_tick.png');
  }

  private formTitle(): string {
      return 'Projects';
  }

  private getColumnClass(multilineHeader: boolean = false): string {
    return 'grid-header' + (multilineHeader ? ' grid-header-multiline' : '');
  }

  private editProject(projectId: string) {
    if (projectId !== '') {
      this.$router.push({ path: '/projects/id/' + projectId });
    }
  }

  private navImport() {
    this.changeRoute('/projects/import');
  }

  private getProjects() {
    if (!this.hideFacets) {
      this.getFilteredProjects();
    } else if (this.payeeId) {
      this.getPayeeProjects();
    } else if (this.clientId) {
      this.getClientProjects();
    } else if (this.communityId) {
      this.getCommunityProjects();
    }
  }

  private getFilteredProjects() {
    this.projectsLoading = true;
    this.aggSearchCriteria.hasPayer = this.currentOrg;
    searchProjects(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.searchCriteria, this.projectSearchCriteria, this.aggSearchCriteria, [ this.$store.getters['local/CURRENT_ORG'] ])
    .then((response) => {
      this.projects = response;
      // On the first unfiltered response set the facets.
      if (this.bInitialLoad) {
        setProjectsSearchFacets(this.searchCriteria, this.projects);
      }
      this.projectCount = +((this.projects.searchResults as SearchResults).resultMeta as ResultMeta).matched;
    }).catch((error) => {
      handleError(this.arrErrors, error);
    }).finally(() => {
      this.projectsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private getPayeeProjects() {
    this.payeeProjectsLoading = true;
    getPayeeProjects(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.aggSearchCriteria, this.payeeId ? this.payeeId : this.clientId).then((response) => {
      this.payeeProjects = response;
      this.allProjects = [];
      this.projectCount = +((this.payeeProjects.searchResults as SearchResults).resultMeta as ResultMeta).matched;
      for (const project of this.payeeProjects.searchResults.results as Project[]) {
         this.allProjects.push(project);
        }
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.payeeProjectsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private getClientProjects() {
    this.payeeProjectsLoading = true;
    getClientProjects(this.mapSearchParams(this.pagination, this.searchTerms, this.sortMappings), this.aggSearchCriteria, this.payeeId ? this.payeeId : this.clientId).then((response) => {
      this.payeeProjects = response;
      this.allProjects = [];
      this.projectCount = +((this.payeeProjects.searchResults as SearchResults).resultMeta as ResultMeta).matched;
      for (const project of this.payeeProjects.searchResults.results as Project[]) {
        this.allProjects.push(project);
      }
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.payeeProjectsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private getCommunityProjects() {
    this.communityProjectsLoading = true;
    searchAggregatesByRelation(this.searchCriteria, 'icproject', this.communityId)
    .then((response) => {
      this.communityProjects = response;
      this.projectCount = +((this.communityProjects.searchResults as SearchResults).resultMeta as ResultMeta).matched;
    }).catch((error) => {
      this.$emit('error', error);
    }).finally(() => {
      this.communityProjectsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private getVendors() {
    this.vendorsLoading = true;
    const emptySearchCriteria: GeneralSearchCriteria = _.cloneDeep(EmptyGeneralSearchCriteria);
    searchAggregatesByRelation(emptySearchCriteria, 'client', this.currentOrg)
    .then((response) => {
      this.vendors = response;
    }).catch((error) => {
      this.arrErrors.push(error);
    }).finally(() => {
      this.vendorsLoading = false;
      this.bInitialLoad = false;
    });
  }

  private deleteProject(id: string, name: string, etag: string) {
    this.deleteProjectId = id;
    this.deleteProjectEtag = etag;
    this.bDeleteProjectModal = true;
  }

  private performDeleteProject() {
    deleteProject(this.deleteProjectId, this.deleteProjectEtag)
    .then((response) => {
      this.closeDelete();
    }).catch((error) => {
    handleError(this.arrErrors, error);
    });
  }
}
